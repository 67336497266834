import { Slot } from "@radix-ui/react-slot";
import { VariantProps } from "class-variance-authority";
import React from "react";
import { cn } from "../utils";
import { tagInner, tagVariants } from "./tag.styles";

interface TagProps extends VariantProps<typeof tagVariants> {
  children: React.ReactNode;
  asChild?: boolean;
  className?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
}

export const Tag = ({ asChild, size, variant, className, children, suffix, prefix, color, ...props }: TagProps) => {
  const Comp = asChild ? Slot : "span";
  const classes = cn(tagVariants({ size, variant, className, color }));
  return (
    <Comp className={classes} {...props}>
      <div className={tagInner()}>
        {prefix ? <span>{prefix}</span> : null}
        {children ? <span>{children}</span> : null}
        {suffix ? <span>{suffix}</span> : null}
      </div>
    </Comp>
  );
};
